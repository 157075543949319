body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

table {
  table-layout: fixed;
  width: 100%;
}

.username {
  font-weight: bold;
  color: purple ;
}


.podcast-listened {
  font-weight: normal;
  color:darkgray;
}
.podcast-new {
  font-weight: bold;
}
.podcast-halfway {
  font-weight: normal;
}

.podcast-list {
  padding: 1px;
  border-radius: 0cqb;
}

.form-caption {
  font-weight: bold;
  width: 150px
}


.podcast-list th, .podcast-list td {
  border-bottom: 1px solid #ccc;
}

.podcast-list tr:hover {
  background-color: lightseagreen;
}

.program-list-item {
  border-bottom: 2px solid #ccc;
  padding-top: 10px;
  padding-bottom: 10px;
}

.program-title {
  align-content: top;
  font-size: x-large;
  font-weight: bolder;
}
.program-description {
  font-style: italic;
  font-size: small;
  color:rgb(71, 30, 255) 
}

.program-list {
  font-size: large;
  padding-top: 3px;
  padding-bottom: 3px;
}


.just-layout {
  border: none;
}

/* Applies to all elements with <... class="someclass"> specified. */

.timestamp { width: 5% ;  font-size: xx-small ; font-weight: bold; padding: 0;}
.seconds { font-size:small }

.language1 { width : 43%; }
.language2 { width : 43%; }

.language1edit { width : 45%; font-size : large }
.language2edit { width : 45%; font-size : large }

.large-transcript {
   width : 90%;
   font-size: xx-large;
 }
.highlighted { color: blue; font-weight: bold; }


.header-div {  
  height: 120px;
  color: darkgreen;
  background-color:rgb(117, 178, 205);
  overflow-y: hidden;
}

.podcast-title {
  color: blue;
}
.scrollable-div {
  /* Set the height and width of the div (optional) */
  height: 730px; /* Adjust the height as needed */
  /*width: 100%; /* Adjust the width as needed */

  /* Add a border (optional) */
  border: 1px solid #ccc;

  /* Add padding and margin (optional) */
  padding: 0px;
  margin: 0px;

  /* Add the scrollbar */
  overflow: scroll; /* auto or use 'scroll' for always visible scrollbar */
  scroll-behavior: smooth;
}


